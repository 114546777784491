import { Route, Routes} from 'react-router-dom'

// Components
import Header from './components/Header.js'
import Footer from './components/Footer.js'
// Pages
import Contact from './pages/Contact/Contact'
import Home from './pages/Home/Home.js'

import './App.css'


function App() {
  return (
    <div className="App">
    <Header />
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/contact/:id' element={<Contact />} />
    </Routes>
    <Footer />
    </div>
  );
}

export default App;
