

const ServiceDescription = (props) => {

    const {title, description} = props

    return (
        <div className="service-description window">
            <h4>{title}</h4>
            <p>{description}</p>
        </div>
    )
}

export default ServiceDescription