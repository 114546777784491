import "98.css";
import "./home.css";


import {Link} from 'react-router-dom';

// List of services --> toilet repair/installation, faucet repair/installation, pipe repair/installation, emergency/red siren, drain cleaning, general plumbing repair, water heater repair/replacement

// Icons
import toiletRepair from "../../assets/finalToilet.png";
import faucet from "../../assets/finalFaucet.png";
import generalPlumbing from "../../assets/finalGeneralPlumbing.png";
import plunger from "../../assets/finalPlunger.png";
import brokenPipe from "../../assets/finalBrokenPipe.png";
import waterHeater from "../../assets/finalWaterHeater.png";
import siren from "../../assets/siren.png";

const Home = () => {
  return (
    <div className='home-container'>
      <div className='emergency-container'>
        <a href="tel:+14705538554" className='emergency-button window'>
          <img className='siren-logo' src={siren} alt='icon of a red siren' />
          <p>Emergency Plumbing Service</p>
          <img className='siren-logo' src={siren} alt='icon of a red siren' />
        </a>
      </div>

      <div className='services-title-container'>
        <div >
          <h2 className='services-title title-bar-text'>Services</h2>
        </div>
      </div>

      <div className='services-container'>
        <div className='service-option'>
          <Link to='/contact/toilets'>
          <div className='window'>
            <p>Toilets</p>
            <img
              className='toilet-logo'
              src={toiletRepair}
              alt='a pixel art toilet with a wrench beside it'
            />
          </div>
          </Link>
        </div>

        <div className='service-option'>
          <Link to="/contact/faucets">
          <div className='window'>
            <p>Faucets</p>
            <img
              className='faucet-logo'
              src={faucet}
              alt='a pixel art faucet with a single droplet falling out of it'
            />
          </div>
          </Link>

        </div>
        <div className='service-option'>
          <Link to='/contact/pipes'>
          <div className='window'>
            <p>Pipes</p>
            <img
              className='pipe-logo'
              src={brokenPipe}
              alt='a pixel art broken pipe'
            />
          </div>
          </Link>
        </div>
        <div className='service-option'>
          <Link to="/contact/drainCleaning">
          <div className='window'>
            <p>Drain Cleaning</p>
            <img
              className='plunger-logo'
              src={plunger}
              alt='a pixel art plunger'
            />
          </div>
          </Link>
        </div>
        <div className='service-option'>
          <Link to="/contact/waterHeaters">
          <div className='window'>
            <p>Water Heaters</p>
            <img
              className='heater-logo'
              src={waterHeater}
              alt='a pixel art water heater'
            />
          </div>
          </Link>
        </div>
        <div className='service-option'>
          <Link to="/contact/generalPlumbing">
          <div className='window'>
            <p>General Plumbing</p>
            <img
              className='plumbing-logo'
              src={generalPlumbing}
              alt='a pixel art wrench and screwdriver crossed to make an "x"'
            />
          </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
