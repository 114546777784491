

import facebook from '../assets/icons/flat_social_icon__facebook__by_gasara_dazqdqy.gif'

import instagram from '../assets/icons/flat_social_icon__instagram__by_gasara_dazqdqs.gif'

import email from '../assets/icons/monochrome_social_icon__e_mail__by_gasara_d9spy3g.gif'

const Footer = () => {
    return (
        <div className='footer-container'>
            <div className="footer window">

                <div className="socials">
                    <a href="https://www.instagram.com/olaizcasacare/">
                        <img src={instagram} alt='instagram logo' />
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=61557592686468">
                        <img src={facebook} alt='facebook logo' />
                    </a>
                    <a href="mailto:jose@olaizcasacare.com">
                        <img src={email} alt='email icon' />
                    </a>
                </div>

                <p>Built By <a href="https://jesusolaiz.com">Jesus Olaiz</a></p>

            </div>
        </div>
    )
}

export default Footer