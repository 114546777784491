
import {Link} from 'react-router-dom';

import '98.css'

import logo from '../assets/logo.png'

const Header = () => {
    return (
        <div className="header">
            <div className='window'>
                <Link className="header-logo" to='/'>
                <img src={logo} alt='hand gripping a wrench within a circle that displays text along the border saying Olaiz Casa Care' />
                </Link>
            </div>
        </div>
    )
}

export default Header