
import {useState, useEffect} from 'react'

// components
import ServiceDescription from '../../components/ServiceDescription'

import { send } from "@emailjs/browser";
import { useParams } from 'react-router-dom';

import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import "./contact.css";


const serviceDescription = {
  toilets:[ "Toilets","Welcome to Olaiz Casa Care, your trusted partner for professional toilet repair and installation services. Our team of experienced technicians is dedicated to ensuring your bathroom fixtures function flawlessly. Whether you need a quick repair for a leaking toilet or a complete installation of a new toilet system, we've got you covered. We prioritize quality workmanship, timely service, and customer satisfaction. Contact us today for efficient and reliable toilet repair and installation solutions."],

  faucets: [ "Faucets","Discover top-notch faucet repair and installation services with Olaiz Casa Care. Our skilled technicians specialize in fixing all types of faucet issues, from leaks and drips to malfunctioning handles and valves. Need a new faucet installed? We handle that too, ensuring precise installation for optimal performance. At Olaiz Casa Care, we pride ourselves on delivering prompt, reliable service and exceptional craftsmanship. Say goodbye to faucet troubles and hello to smooth, efficient water flow. Contact us today for expert faucet repairs and installations."],

  pipes: ["Pipe Repairs" ,"Facing a pipe leak or burst? Don't worry, Olaiz Casa Care is here to help with expert pipe repair services. Our team of skilled technicians is equipped to handle a wide range of pipe issues, from minor leaks to major repairs. We use advanced techniques and quality materials to ensure lasting solutions and prevent further damage. Whether it's a residential or commercial property, we offer timely response and professional service to restore your plumbing system's integrity. Trust Olaiz Casa Care for reliable pipe repairs that keep your property safe and functional."],

  drainCleaning: [ "Drain Cleaning" ,"Say goodbye to clogged drains with Olaiz Casa Care's professional drain clearing services. Our experienced team uses state-of-the-art equipment to efficiently remove blockages from sinks, showers, toilets, and sewer lines. Whether it's a minor clog or a stubborn obstruction, we have the expertise to restore proper drainage quickly and effectively. Don't let a clogged drain disrupt your daily routine. Contact Olaiz Casa Care for fast and reliable drain clearing solutions that keep your plumbing running smoothly."],

  waterHeaters: ["Water Heaters", "Experience reliable water heater repair and installation services with Olaiz Casa Care. Our skilled technicians specialize in diagnosing and fixing issues with water heaters, including leaks, heating element malfunctions, and thermostat problems. Need a new water heater installed? We handle that too, ensuring proper setup and efficient performance. Whether it's a traditional tank-style heater or a tankless system, we have the expertise to meet your hot water needs. Count on Olaiz Casa Care for prompt, professional service and lasting solutions for your water heater."],

  generalPlumbing: ["General Plumbing", "Trust Olaiz Casa Care for all your general plumbing needs. Our experienced team offers a comprehensive range of services, including leak repairs, fixture installations, pipe inspections, and plumbing system maintenance. Whether you're dealing with a minor plumbing issue or need a complete system overhaul, we have the knowledge and skills to get the job done right. With a focus on quality workmanship and customer satisfaction, we strive to exceed your expectations. Contact Olaiz Casa Care for reliable and efficient general plumbing services that keep your home or business running smoothly."]


}






const Contact = () => {

  const [formSent, setFormSent] = useState(false)

  useEffect(() => {
    setFormSent(false)
  }, [])

  const {id} = useParams()
  console.log(id)
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const formSchema = Yup.object({
    name: Yup.string()
      .min(3, "Name must be atleast 3 characters long.")
      .required("Please enter your First and Last name"),
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required("Please enter a valid email address."),
    number: Yup.string()
      .matches(phoneRegExp, "Please enter a valid phone number")
      .required("Please enter a valid phone number"),
    zipcode: Yup.number()
      .typeError("Zipcode must be a number.")
      .test(
        "len",
        "Zip code must be 5 digits",
        (val) => val.toString().length === 5
      )
      .required("Please enter a valid zipcode."),
    serviceDetails: Yup.string()
      .min(4, "The description needs to be atleast 4 characters long.")
      .required("We need a short description of what you need help with."),
  });

  const initialFormState = {
    name: "",
    email: "",
    number: "",
    zipcode: "",
    serviceDetails: "",
  };



  const EMAIL_SERVICE_KEY = process.env.REACT_APP_EMAIL_SERVICE_KEY;
  const EMAIL_TEMP = process.env.REACT_APP_EMAIL_TEMP;
  const EMAIL_PUBLIC_KEY = process.env.REACT_APP_EMAIL_PUBLIC_KEY;


  const sendEmail = (values) => {
    send(EMAIL_SERVICE_KEY, EMAIL_TEMP, values, EMAIL_PUBLIC_KEY)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  const renderError = (message) => <p className='error'>{message}</p>;

  return (
    <div className='contact-page-container'>
      <div className='form-container window'>
      {
        formSent ? 
        <h3>Thank you for reaching out!</h3>
        :
        <>
        <h3>Contact Us!</h3>
        <Formik
          initialValues={initialFormState}
          validationSchema={formSchema}
          onSubmit={async (values, { resetForm }) => {
            sendEmail(values)
            resetForm();
            setFormSent(true)
            
            
          }}
        >
          <Form className='contactForm' id='contactForm'>
            <div className='customer-detail-inputs'>
              <div className='fieldset name field-row-stacked'>
                <label htmlFor="name">Full Name</label>
                <Field
                  className='contactInput'
                  id='name'
                  name='name'
                  type='text'
                />
                <ErrorMessage name='name' render={renderError} />
              </div>

              <div className='fieldset email field-row-stacked'>
                <label htmlFor="email">Email Address</label>
                <Field
                  className='contactInput'
                  id='email'
                  name='email'
                  type='text'
                />
                <ErrorMessage name='email' render={renderError} />
              </div>

              <div className='fieldset number field-row-stacked'>
                <label htmlFor="number">Phone Number</label>
                <Field
                  className='contactInput'
                  id='number'
                  name='number'
                  type='text'
                />
                <ErrorMessage name='number' render={renderError} />
              </div>

              <div className='fieldset zipcode field-row-stacked'>
                <label htmlFor="zipcode">Zipcode</label>
                <Field
                  className='contactInput'
                  id='zipcode'
                  name='zipcode'
                  type='text'
                />
                <ErrorMessage name='zipcode' render={renderError} />
              </div>
            </div>
            <div className='fieldset message field-row-stacked'>
              <label htmlFor="name">Service Description</label>
              <Field
                as='textarea'
                id='serviceDetails'
                name='serviceDetails'
                cols='10'
                rows='6'
              />
              <ErrorMessage name='serviceDetails' render={renderError} />
            </div>

            <button type='submit' className='submitFormButton'>
              Send
            </button>
          </Form>
        </Formik>

        </>
      }
      </div>

      <ServiceDescription title={serviceDescription[id][0]} description={serviceDescription[id][1]} />
    </div>
  );
};

export default Contact;
